<template>
  <v-container
    id="banner"
    fluid
    class="pa-0 ma-0"
  >
    <v-carousel
      :cycle="true"
      :interval="4000"
      :hide-delimiters="true"
      :hide-delimiter-background="true"
      :progress="true"
      progress-color="#999999"
      show-arrows-on-hover
      height="auto"
    >
      <v-carousel-item
        v-for="(item,i) in cycles"
        :key="i"
      >
        <v-img
          width="100%"
          :src="is_mobile? item.msrc : item.pcsrc"
          :style="{cursor: item.url ? 'pointer':''}"
          @click.stop="intoUrl(item.url)"
        />
      </v-carousel-item>
    </v-carousel>
    <div v-if="false" class="cs-timeout white--text">
      <div class="d-flex algin-center pb-4 pb-sm-2 pb-md-3 pb-lg-4">
        <div class="pa-0 mx-1 rounded text-center d-flex flex-column algin-center justify-center number-wrap">
          <div class="text-h5 white--text font-weight-bold number-base">{{ days }}</div>
          <div>天</div>
        </div>
        <div class="pa-0 mx-1 white--text rounded text-center d-flex flex-column algin-center justify-center number-wrap">
          <div class="text-h5 font-weight-bold number-base">{{ hours }}</div>
          <div>小时</div>
        </div>
        <div class="pa-0 mx-1 white--text rounded text-center d-flex flex-column algin-center justify-center number-wrap">
          <div class="text-h5 font-weight-bold number-base">{{ minutes }}</div>
          <div>分钟</div>
        </div>
        <div class="pa-0 mx-1 white--text rounded text-center d-flex flex-column algin-center justify-center number-wrap">
          <div class="text-h5 font-weight-bold number-base">{{ seconds }}</div>
          <div>秒</div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import Tools from '@/mixins/Tools.js'
import { BANNERCENTER } from '@/datacenter.js'
export default {
  name: 'IndexCarousel',
  mixins: [Tools],
  data () {
    return {
      event_start_time: '2022-09-24 09:00',
      event_end_time: '2022-09-24 17:30:00',
      event_type: '0',
      diff: 1,
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      t_timeout: null
    }
  },
  computed: {
    cycles() {
      return BANNERCENTER
    }
  },
  mounted() {
    // this.future_time_formatter(this.event_start_time, this.event_end_time)
  },
  beforeDestroy() {
    // clearTimeout(this.t_timeout)
  },
  methods: {
    // 会议进行倒计时
    future_time_formatter(event_start_time, event_end_time, event_type='0') {
      // 替换为满足ios平台的时间格式
      let tmp_event_start_time = event_start_time.replace(/-/g, "/");
      let tmp_event_end_time = event_end_time.replace(/-/g, "/");
      let start_time = Date.parse(tmp_event_start_time);
      let end_time = Date.parse(tmp_event_end_time);
      let current = (new Date()).valueOf();
      // 会议时间截止后不再倒计时
      if (current >= end_time) {
          return false;
      }
      this.diff = (start_time - current) / 1000;
      this.days = Math.floor(this.diff / (60 * 60 * 24));
      this.hours = Math.floor((this.diff / (60 * 60)) % 24);
      this.minutes = Math.floor((this.diff / 60) % 60);
      this.seconds = Math.floor(this.diff % 60);
      this.t_timeout = setTimeout(()=> {
        if (this.diff > 0) {
          this.future_time_formatter(event_start_time, event_end_time, event_type);
        } else {
          clearTimeout(this.t_timeout)
        }
      }, 1000);
    },
    intoUrl(url) {
      if (!!url) {
        // 跳转进入直播间交流
        document.location.href = url
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-timeout
  position: absolute
  left: 50%
  top: 100%
  transform: translate(-50%, -100%)
.number-wrap
  background-color: #01BDCB !important
  width: 46px
  height: 60px
.number-base
  min-width: 40px
  line-height: 1
</style>